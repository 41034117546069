<template>
	<div class="bg-gray-100 rounded overflow">
		<div class="flex justify-between align-items-center p-2">
			<button @click="clearDataField('all')" class="bg-white text-xs rounded border shadow-sm p-1">{{
					$t('clear')
				}}
			</button>
			<small class="text-xs">{{ $t('filter') }}</small>
			<button @click="filterDataNow" class="bg-blue-400 text-xs text-white rounded border shadow-sm p-1">
				{{ $t('done') }}
			</button>
		</div>
		<div class="flex flex-col">
			<div>
				<div>
					<div class="bg-white border-b">
						<label class="inline-flex align-items-center item-center mt-1 ml-3">
							<input type="radio" class="form-radio cursor-pointer" v-model="filter" value="relevant"/>
							<span class="ml-2 cursor-pointer text-xs">{{ $t('relevantCustomer') }}</span>
						</label>
					</div>
					<div v-if="filter === 'relevant'" class="p-2">
						<div class="form-group">
							<select v-model="relevantCustomer.name" class="form-select w-full">
								<option selected disabled>---</option>
								<option value='true'>{{ $t('yes') }}</option>
								<option value='false'>{{ $t('no') }}</option>
							</select>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex"
export default {
	name: 'FilterComponent',
	props: {
		data: {
			type: String,
			required: false
		},
		top: {
			type: Boolean,
			required: false
		}
	},
	data() {
		return {
			filter: '',
			value1: '',
			relevantCustomer: {
				name: ''
			}
		}
	},
	computed:{
		...mapGetters({
			GET_CUSTOMER_FILTER: 'portal/GET_CUSTOMER_FILTER'
		})
	},
	watch: {
		filter: function (newVal, _) {
			this.filter = ''
			this.filter = newVal
		}
	},
	methods: {
		filterDataNow() {
			if (this.filter === 'relevant') {
				if (this.relevantCustomer && this.relevantCustomer.name) {
					this.$store.commit('portal/SET_CUSTOMER_ACTIVE_FILTER', {
						mode: 'is_relevant',
						value: this.relevantCustomer.name
					})
					// this.$emit('filterRelevantCustomers', this.relevantCustomer)
				} else {
					this.$store.commit('portal/REMOVE_CUSTOMER_FILTER', {mode: 'is_relevant'})
				}
			}
			window.Bus.$emit('handle-customer-filter')
			// this.clearDataField()
			this.$emit('hideAdvanceFilter')
		},

		clearDataField(d = null) {
			this.filter = ''
			Object.keys(this.relevantCustomer).forEach(element => {
				this.relevantCustomer[element] = ''
			})
			if (d !== null) {
				this.$store.commit('portal/FILTER_ALL')
			}
			this.$store.commit('portal/REMOVE_CUSTOMER_FILTER', {mode: 'is_relevant',})
			window.Bus.$emit('reset-customers-settings')
		}
	},
	mounted() {
		this.GET_CUSTOMER_FILTER.map(item => {
			if (item.mode === 'is_relevant') {
				this.filter = 'relevant'
				this.relevantCustomer.name = item.value
			}
		})
	}
}
</script>

<style lang="scss" scoped>
@import "./../../../assets/variables.scss";

.el-date-editor--daterange.el-input__inner {
	width: unset !important;
}

.form-group {
	margin-bottom: 0.5rem !important;
}

.form-control, .form-select {
	border: 1px solid $form-border !important;
	height: unset !important;
	padding: .175rem .75rem !important;
}

.form-control:focus {
	box-shadow: unset !important;
	border: 1px solid $theme-primary-color !important;
}
</style>
