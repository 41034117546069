<template>
	<tr>
		<td class="text-xs"><input v-model="customer.checked" :value="customer.checked" @change="checkboxOrder"
								   class="checkbox-height" type="checkbox"/></td>
		<td class="text-xs"><span v-if="customer.customer_number === null">----</span><span
			v-else>{{ customer.customer_number }}</span></td>
		<td class="text-xs"><span v-if="customer.customer_name === null">----</span><span
			v-else>{{ customer.customer_name }}</span></td>
		<!-- <td class="text-xs">{{ customer.organization_number }}</td> -->
		<td class="text-xs"><span v-if="customer.category === null">----</span><span v-else>{{
				customer.category.name
			}}</span></td>
		<td class="text-xs">
			<span v-if="customer.category === null">----</span>
			<span v-else>{{ customer.category.account_number }}</span>
		</td>
		<td class="text-xs">
			<span v-if="customer.category === null">----</span>
			<span v-else>
                <span v-if="customer.category.cost_center">{{ customer.category.cost_center.description }}</span>
                <span v-else>----</span>
            </span>
		</td>
		<td>
			<button @click="openRelevantModal(customer)"
					class="flex justify-between align-items-center bg-green-400 rounded-md text-white px-2 py-1 space-x-3"
					v-if="customer.is_relevant">
				<span>{{ $t('yes') }}</span>
				<svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24"
					 stroke="currentColor">
					<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/>
				</svg>
			</button>
			<button @click="openRelevantModal(customer)"
					class="flex justify-center align-items-center bg-red-500 rounded-md text-white px-2 py-1 space-x-3"
					v-else>
				<span>{{ $t('no') }}</span>
				<svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 text-white" viewBox="0 0 20 20"
					 fill="currentColor">
					<path fill-rule="evenodd"
						  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
						  clip-rule="evenodd"/>
				</svg>
			</button>
		</td>
		<td>
			<span>{{ customer.total_order_amount }}</span>
		</td>
		<td>
			<div class="flex" style="position: relative">
				<span class="cursor-pointer mr-2" @click="openCustomerEditModal(customer)" :title="$t('editCustomer')">
					<i class="iconly-brokenEdit"></i>
				</span>

				<span @click="openCustomerDeliveryDrawer(customer)" class="cursor-pointer mr-2 "
					  :title="$t('deliveryAddress')">
					<svg width="17" height="24" stroke-width="1.5" viewBox="0 0 24 24" fill="none"
						 xmlns="http://www.w3.org/2000/svg">
						<path
							d="M8 19C9.10457 19 10 18.1046 10 17C10 15.8954 9.10457 15 8 15C6.89543 15 6 15.8954 6 17C6 18.1046 6.89543 19 8 19Z"
							stroke="currentColor" stroke-miterlimit="1.5" stroke-linecap="round"
							stroke-linejoin="round"/>
						<path
							d="M18 19C19.1046 19 20 18.1046 20 17C20 15.8954 19.1046 15 18 15C16.8954 15 16 15.8954 16 17C16 18.1046 16.8954 19 18 19Z"
							stroke="currentColor" stroke-miterlimit="1.5" stroke-linecap="round"
							stroke-linejoin="round"/>
						<path d="M10.05 17H15V6.6C15 6.26863 14.7314 6 14.4 6H1" stroke="currentColor"
							  stroke-linecap="round"/>
						<path d="M5.65 17H3.6C3.26863 17 3 16.7314 3 16.4V11.5" stroke="currentColor"
							  stroke-linecap="round"/>
						<path d="M2 9L6 9" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
						<path
							d="M15 9H20.6101C20.8472 9 21.0621 9.13964 21.1584 9.35632L22.9483 13.3836C22.9824 13.4604 23 13.5434 23 13.6273V16.4C23 16.7314 22.7314 17 22.4 17H20.5"
							stroke="currentColor" stroke-linecap="round"/>
						<path d="M15 17H16" stroke="currentColor" stroke-linecap="round"/>
					</svg>
				</span>
				<span class="cursor-pointer " @click="openBuyer" v-if="customer.user !== null"
					  :title="$t('accessBuyerDashboard')">
					<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
						 x="0px" y="0px"
						 width="17" height="24" viewBox="0 0 100 100" enable-background="new 0 0 100 100"
						 xml:space="preserve">
<g id="_x37_7_Essential_Icons">
	<path id="View"
		  d="M50,34c-8.8,0-16,7.2-16,16s7.2,16,16,16s16-7.2,16-16S58.8,34,50,34z M50,62c-6.6,0-12-5.4-12-12s5.4-12,12-12    s12,5.4,12,12S56.6,62,50,62z M94.4,48.6l-8.6-8.7C76.3,30.3,63.6,25,50,25s-26.3,5.3-35.8,14.9l-8.6,8.7c-0.8,0.8-0.8,2,0,2.8    l8.6,8.7C23.7,69.7,36.4,75,50,75s26.3-5.3,35.8-14.9l8.6-8.7C95.2,50.6,95.2,49.4,94.4,48.6z M83,57.3C74.2,66.1,62.5,71,50,71    s-24.2-4.9-33-13.7L9.8,50l7.2-7.3C25.8,33.9,37.5,29,50,29s24.2,4.9,33,13.7l7.2,7.3L83,57.3z"/>
</g>
    <g id="Guides">
</g>
    <g id="Info">
	<g id="BORDER">
		<path fill="#0000FF" d="M944-790V894H-840V-790H944 M952-798H-848V902H952V-798L952-798z"/>
	</g>
</g>
</svg>

				</span>
			</div>
		</td>
	</tr>
</template>

<script>
import {saveBuyer, removeLocalCompany,clearCartItem} from "../../../services/storage-window";
import {mapGetters} from "vuex"

export default {
	name: 'CustomerTableComponent',
	props: {
		customer: {
			type: Object,
			require: true,
		}
	},
	data() {
		return {
			checked: false,
		}
	},
	computed: {
		...mapGetters({
			GET_COMPANY: 'portal/GET_COMPANY_USER'
		})
	},
	methods: {
		openCustomerEditModal(data) {
			this.$emit('openCustomerEdit', data)
		},
		checkboxOrder(e) {
			this.$store.commit('portal/TOGGLE_CUSTOMER_CHECKBOX', {id: this.customer.id, value: this.customer.checked})
		},
		openRelevantModal(data) {
			this.$emit('openRelevantModal', data)
		},
		openCustomerDeliveryDrawer: function (data) {
			window.Bus.$emit('open-customer-drawer', data)
		},
		openBuyer: function () {
			if (this.customer.user) {
				const loading = this.$loading({
					lock: true,
					text: this.$t('settingUpAccount'),
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				})
				let payload = {
					'buyer_id': this.customer.user.id,
					'referrer': 'seller',
					'company_id': this.GET_COMPANY.id,
				}
				// removeLocalCompany()
				saveBuyer(payload)
				let routeData = this.$router.resolve({
					name: 'productarticles',
					params: {company_id: this.GET_COMPANY.id}
				});
				clearCartItem()
				setTimeout(function () {
					loading.close()
					window.open(routeData.href, '_blank')
				}, 500)
			} else {
				this.$services.helpers.notification(this.$t('notConnectedToCompany'), 'error', this)
			}
		}

	}
}
</script>

<style lang="scss" scoped>
.table tbody tr > td {
	padding: 0.4rem 0.55rem !important;
	font-size: 13px !important;
	color: #6F6F6F !important;
}

.edit-button {
	position: relative;
	top: 3px;
	margin-right: 4px;
}
</style>
